var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dataMigration-wrapper" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "topback" },
        [
          _c(
            "el-button",
            {
              staticClass: "saveButton",
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.back },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "contentImtem" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "label-position": "right",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c("div", { staticClass: "conentLayout" }, [
                _c(
                  "div",
                  { staticClass: "tipdetailOne" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "configName", label: "配置名称：" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "160",
                            placeholder: "请输入配置名称",
                          },
                          model: {
                            value: _vm.formInline.configName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "configName", $$v)
                            },
                            expression: "formInline.configName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "operationId", label: "所属商户：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", size: "15" },
                            on: { change: _vm.changeDiCi },
                            model: {
                              value: _vm.formInline.operationId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "operationId",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.operationId",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "全部", value: "" },
                            }),
                            _vm._l(_vm.tenantList, function (value) {
                              return _c("el-option", {
                                key: value.operationId,
                                attrs: {
                                  label: value.operationName,
                                  value: value.operationId,
                                },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "conentLayout" }, [
                _c("span", { staticClass: "tipicon" }),
                _c("b", { staticClass: "tiptitle" }, [_vm._v("自主入场模式")]),
                _c(
                  "div",
                  { staticClass: "tipdetail tipdetailTop" },
                  [
                    _vm._v(
                      " PDA泊位、地磁泊位开启自主入场后，车主在车场的有效范围内可以自主入场，车主可以在移动端进行自主停车，查看"
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "blueColor",
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = true
                          },
                        },
                      },
                      [_vm._v("移动端呈现效果图")]
                    ),
                    _vm._v("。 "),
                    _c("el-switch", {
                      staticClass: "switch",
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: _vm.formInline.selfEntry,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "selfEntry", $$v)
                        },
                        expression: "formInline.selfEntry",
                      },
                    }),
                  ],
                  1
                ),
                _vm.formInline.selfEntry == 1
                  ? _c(
                      "div",
                      { staticClass: "tipdetail" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { padding: "15px 0px" },
                            attrs: {
                              label: "配置有限范围",
                              prop: "entryDistance",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputStyle",
                              model: {
                                value: _vm.formInline.entryDistance,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInline, "entryDistance", $$v)
                                },
                                expression: "formInline.entryDistance",
                              },
                            }),
                            _c("span", { staticClass: "unit" }, [_vm._v("米")]),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "introduce" }, [
                          _vm._v(
                            " 有效范围，指划定车主可以进行自主入场的区域，即以车场所设经纬度为圆心，有效范围为半径的区域。取值范围 [ 100,10000 ]。 "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "tipdetail" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: {
                          padding: "15px 0px",
                          "margin-left": "6px",
                        },
                        attrs: { label: "配置免费时长", prop: "freeTime" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputStyle",
                          model: {
                            value: _vm.formInline.freeTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "freeTime", $$v)
                            },
                            expression: "formInline.freeTime",
                          },
                        }),
                        _c("span", { staticClass: "unit" }, [_vm._v("分钟")]),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "introduce" }, [
                      _vm._v(
                        " 此参数仅用于“仅限免费时长启动PDA审核”配合使用，取值范围 [ 0,240 ]；如果用户停车时长超过配置分钟数，PDA无需确认，反之，用于停车时长不足配置分钟数据PDA需要确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间”。 "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "conentLayout" }, [
                _c("span", { staticClass: "tipicon" }),
                _c("b", { staticClass: "tiptitle" }, [_vm._v("自主离场模式")]),
                _c(
                  "p",
                  { staticClass: "tipdetail tipdetailTop" },
                  [
                    _c("el-switch", {
                      staticClass: "switch",
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: _vm.formInline.selfExit,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "selfExit", $$v)
                        },
                        expression: "formInline.selfExit",
                      },
                    }),
                  ],
                  1
                ),
                _vm.formInline.selfExit == 1
                  ? _c(
                      "div",
                      [
                        _c("p", { staticClass: "tipdetail" }, [
                          _vm._v(
                            " 开启【申请驶离】车主可以在移动端自主驶离，查看"
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "blueColor",
                              on: {
                                click: function ($event) {
                                  _vm.dialogVisible1 = true
                                },
                              },
                            },
                            [_vm._v("移动端呈现效果图")]
                          ),
                          _vm._v("。开启【场中支付】车主可以在移动端支付驶离,"),
                          _c(
                            "span",
                            {
                              staticClass: "blueColor",
                              on: {
                                click: function ($event) {
                                  _vm.dialogVisible2 = true
                                },
                              },
                            },
                            [_vm._v("移动端呈现效果图")]
                          ),
                          _vm._v("。 "),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "10px 0px 5px 34px",
                              color: "#333",
                              "font-size": "12px",
                            },
                          },
                          [_vm._v(" 配置驶离规则： ")]
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "tipdetail configRule",
                            attrs: { prop: "exitRule" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "exitRileGroup",
                                model: {
                                  value: _vm.formInline.exitRule,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "exitRule", $$v)
                                  },
                                  expression: "formInline.exitRule",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "radioRule" },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("PDA全程审核"),
                                    ]),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.formInline.exitRule == 1,
                                            expression:
                                              "formInline.exitRule == 1",
                                          },
                                        ],
                                        staticClass: "introduce",
                                      },
                                      [
                                        _vm._v(
                                          " 车主自主驶离，由PDA确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间” "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { staticClass: "radioRule" },
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("仅限免费时长启动PDA审核"),
                                    ]),
                                    _vm.formInline.exitRule == 2
                                      ? _c("p", { staticClass: "introduce" }, [
                                          _vm._v(
                                            " 在免费时长内，车主自主驶离，由PDA确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间” "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { staticClass: "radioRule" },
                                  [
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("信赖车主"),
                                    ]),
                                    _vm.formInline.exitRule == 3
                                      ? _c("p", { staticClass: "introduce" }, [
                                          _vm._v(
                                            " 信赖车主，以车主自主驶离的【申请/支付】时间为停车记录的截止时间 "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.showDici
                                  ? _c(
                                      "el-form-item",
                                      { staticClass: "radioRule" },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 4 } },
                                          [_vm._v("取信地磁")]
                                        ),
                                        _vm.formInline.exitRule == 4
                                          ? _c(
                                              "p",
                                              { staticClass: "introduce" },
                                              [
                                                _vm._v(
                                                  " 取信地磁，以地磁上报驶离的时间为停车记录的截止时间，车主驶离即生成欠费订单；视频泊位不支持取信地磁离场 "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "save" },
        [
          _c(
            "el-button",
            {
              staticClass: "saveButton",
              attrs: { type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("button.preservation")))]
          ),
          _c("el-button", { staticClass: "cancle", on: { click: _vm.back } }, [
            _vm._v("取消"),
          ]),
        ],
        1
      ),
    ]),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible,
          expression: "dialogVisible",
        },
      ],
      staticClass: "mask",
      style: {
        "z-index": "10000",
        "background-image": "url(" + _vm.picUrl + ")",
      },
      on: {
        click: function ($event) {
          _vm.dialogVisible = false
        },
      },
    }),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible1,
          expression: "dialogVisible1",
        },
      ],
      staticClass: "mask",
      style: {
        "z-index": "10000",
        "background-image": "url(" + _vm.picUrl1 + ")",
      },
      on: {
        click: function ($event) {
          _vm.dialogVisible1 = false
        },
      },
    }),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible2,
          expression: "dialogVisible2",
        },
      ],
      staticClass: "mask",
      style: {
        "z-index": "10000",
        "background-image": "url(" + _vm.picUrl2 + ")",
      },
      on: {
        click: function ($event) {
          _vm.dialogVisible2 = false
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }